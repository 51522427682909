import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils"
import EmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from "embla-carousel"

export interface ICarousel {
    className?: string,
    navigation?: boolean,
    emblaOptions?: Partial<EmblaOptionsType>,
    onload?: (data: { dom: HTMLElement, embla: EmblaCarouselType }) => void
}

export class Carousel extends MithrilTsxComponent<ICarousel> {
    embla: EmblaCarouselType

    view(v: m.Vnode<ICarousel>) {
        const canScrollPrev = v.attrs.navigation && this.embla && this.embla.canScrollPrev()
        const canScrollNext = v.attrs.navigation && this.embla && this.embla.canScrollNext()

        return <div className={cleanString("EmblaCarousel embla", v.attrs.className, canScrollPrev && "has-prev", canScrollNext && "has-next")}>
            <div className="embla__viewport">
                <div className="embla__container">
                    {v.children}
                </div>
            </div>

            {v.attrs.navigation && this.embla &&
                <CarouselNav
                    canScrollPrev={canScrollPrev}
                    canScrollNext={canScrollNext}
                    scrollPrev={this.embla.scrollPrev}
                    scrollNext={this.embla.scrollNext}
                />
            }
        </div>
    }

    oncreate(v: m.VnodeDOM<ICarousel>) {
        const wrap = v.dom as HTMLDivElement
        const viewPort = wrap.querySelector(".embla__viewport") as HTMLDivElement
        this.embla = EmblaCarousel(viewPort, {
            align: "center",
            containScroll: "trimSnaps",
            ...v.attrs.emblaOptions ?? {},
        })

        this.embla.on("init", () => setTimeout(m.redraw, 80))
        this.embla.on("select", () => setTimeout(m.redraw, 80))
        this.embla.on("resize", () => setTimeout(m.redraw, 80))

        v.attrs.onload?.({ dom: wrap, embla: this.embla });
        [1000, 2000].forEach(x => setTimeout(m.redraw, x))
    }
}

export interface ICarouselNav {
    canScrollPrev: boolean,
    scrollPrev: Function,
    canScrollNext: boolean,
    scrollNext: Function,
}

export class CarouselNav extends MithrilTsxComponent<ICarouselNav> {
    view(v: m.Vnode<ICarouselNav>) {
        return [
            <button aria-label="slide previous" disabled={!v.attrs.canScrollPrev} className="embla__button embla__button--prev" type="button" onclick={v.attrs.scrollPrev}>
                <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 643.999">
                    <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"></path>
                </svg>
            </button>,
            <button aria-label="slide next" disabled={!v.attrs.canScrollNext} className="embla__button embla__button--next" type="button" onclick={v.attrs.scrollNext}>
                <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
                    <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"></path>
                </svg>
            </button>
        ]
    }
}

export interface ICarouselItem {
    className?: string,
    parallax?: boolean,
}

export class CarouselItem extends MithrilTsxComponent<ICarouselItem> {
    view(v: m.Vnode<ICarouselItem>) {
        return <div className={cleanString("CarouselItem embla__slide", v.attrs.className)}>
            <div className="embla__slide__inner">
                {v.attrs.parallax
                    ? <div className="embla__slide__parallax">
                        {v.children}
                    </div>
                    : v.children
                }
            </div>
        </div>
    }
}

export interface ICarouselImageItem extends ICarouselItem {
    eager?: boolean
    src: string
}

export class CarouselImageItem extends MithrilTsxComponent<ICarouselImageItem> {
    view(v: m.Vnode<ICarouselImageItem>) {
        return <CarouselItem {...v.attrs}>
            <img className="embla__slide__img" loading={v.attrs.eager ? "eager": "lazy"} src={v.attrs.src} />
        </CarouselItem>
    }
}