import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { mounter, isMobileViewport } from "../utils"
import { debounce } from "oj-utils"
import { Carousel } from "../components/Carousel/Carousel"
import { IItem } from "./trackingTypes"

interface IOverview {
    navigateUrl?: string,
    title: string,
    products: IProduct[]
}

interface IProduct {
    hotspotBlockType: number,
    navigateUrl: string,
    openInNewTab: boolean,
    title: string,
    labelImageUrl: string,
    labelRightTopImageUrl: string,
    image: IImage,
    subtextFirstLine: string,
    subtextSecondLine: string,
    actionPrice: number,
    price: number,
    priceText: string,
    buttonText: string,
    buttonType: string,
    backgroundColor?: string
    i?: number
    list?: string
    productId?: string
    editorType?: string
    category?: string
    fujiId?: string
    campaignLabel?: string
    regularPrice?: string
    discountPrice?: string
}

interface IImage {
    title: string,
    altText: string,
    imageUrls: {
        215: string,
        445: string,
        185: string,
        400: string
    }
}

const hasDecimals = (price) => {
    return price % 1 !== 0
}

const formatPrice = (price) => {
    if (!hasDecimals(price)) return `${price}<i class="icon-price-round"></i>`

    const PriceArray = price.toFixed(2).split(".")
    return `${PriceArray[0]}.<sup>${PriceArray[1]}</sup>`
}

export class ProductGridSlider extends MithrilTsxComponent<IOverview> {

    oninit(v) {
        window.addEventListener('resize', debounce(1000, () => {
            m.redraw()
        }))
    }

    view(v: Vnode<IOverview>) {
        const overview: IOverview = v.attrs

        return <div className="product-section">
            <header className="heading">
                {overview.navigateUrl
                    ? <a href={overview.navigateUrl}><h3>{m.trust(overview.title)}</h3></a>
                    : <h3>{m.trust(overview.title)}</h3>
                }
            </header>

            {overview.products.length > 0 && (
                isMobileViewport(992)
                    ? <Carousel navigation emblaOptions={{
                        slidesToScroll: 2,
                        align: "start",
                        containScroll: "trimSnaps"
                    }}>{overview.products.map((x, i) =>
                        <div className="column">
                            {x.hotspotBlockType === 0
                                ? <ProductBlock {...x} i={i} list={overview.title} />
                                : <ProductTipBlock {...x} />
                            }
                        </div>
                    )}</Carousel>
                    : <div className="product-section-grid">
                        {overview.products.map((x, i) =>
                            <div className="column">
                                {x.hotspotBlockType === 0
                                    ? <ProductBlock {...x} i={i} list={overview.title} />
                                    : <ProductTipBlock {...x} />
                                }
                            </div>
                        )}
                    </div>
            )}
        </div>
    }
}

export class ProductBlock extends MithrilTsxComponent<IProduct> {
    view(v: Vnode<IProduct>) {
        const product: IProduct = v.attrs
        const price = product.actionPrice || product.price
        const cats = product.category.split("/").filter(x => x && x !== " ")
        const listName = product.list ??  v.attrs.list;

        const item: IItem = {
            item_id: product.fujiId ?? product.productId,
            item_name: product.title,
            item_category: cats.length > 0 ? cats[0] : null,
            item_category2: cats.length > 1 ? cats[1] : null,
            item_category3: cats.length > 2 ? cats[2] : null,
            price: product.regularPrice || null,
            discount: product.discountPrice || null,
            editor_type: product.editorType,
        }

        const list = {
            item_list_name: listName,
            index: (product.i + 1).toString()
        }

        const itemList = {...item, ...list}

        return <div className="well well-white product-block">
            <a 
                href={product.navigateUrl} 
                title={product.title} 
                target={product.openInNewTab ? "_blank" : "_self"}
                data-onviewport={`window.oj.impressionItem("view_item", { items: [${JSON.stringify({...itemList})}] }, "${listName}")`}
                onclick={() => {
                    window.ojtag("select_item", { items: [{...itemList}] })

                    if(product.campaignLabel)
                        window.ojtag("select_promotion", { items: [item], promo: [{ name: product.campaignLabel }] })
                }}
            >
                
                {product.labelImageUrl && <div className="product-block-label">
                    <img src={product.labelImageUrl} alt="label" />
                </div>}

                {product.labelRightTopImageUrl && <div className="product-block-label right">
                    <img src={product.labelRightTopImageUrl} alt="label" />
                </div>}

                {product.image.imageUrls != null && <figure>
                    <picture>
                        <source srcset={product.image.imageUrls[200] + " 1x"} media="(min-width: 1024px)" />
                        <source srcset={product.image.imageUrls[260] + " 1x"} media="(min-width: 768px)" />
                        <source srcset={product.image.imageUrls[400] + " 1x"} media="(min-width: 450px)" />
                        <img src={product.image.imageUrls[400]} loading="lazy" itemprop="" alt={product.image.altText} title={product.image.title} />
                    </picture>
                </figure>}

                <p className="product-block__info">
                    <span className="product-block__info__title">{product.title}</span>
                    <span className="product-block__info__spec-first">{m.trust(product.subtextFirstLine)}</span>
                    <span className="product-block__info__spec-second">{m.trust(product.subtextSecondLine)}</span>

                    {(price || product.buttonText) &&
                        <span className="product-block__info__footer">
                            {price &&
                                <span className="footer__price-info">
                                    {product.actionPrice && <span className="price-info__price-old">{m.trust(formatPrice(product.price))}</span>}
                                    {product.priceText && <span className="price-info__from">{product.priceText}</span>}

                                    <span className="price-info__price">
                                        {m.trust(formatPrice(price))}
                                    </span>
                                </span>
                            }
                            {product.buttonText &&
                                <span className={"btn btn--" + product.buttonType}>{product.buttonText}</span>
                            }
                        </span>
                    }
                </p>
            </a>
        </div>
    }
}

export class ProductTipBlock extends MithrilTsxComponent<IProduct> {
    view(v: Vnode<IProduct>) {
        const product: IProduct = v.attrs

        return <div className="well product-block product-block--tip" style={"background-color:#" + product.backgroundColor}>
            <a href={product.navigateUrl} title={product.title}>
                <figure>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" data-src="/FrontendWebpack/dist/assets/images/product/transparent-prod-img.png" />
                </figure>

                <p className="product-block__info">
                    <span className="product-block__info__title">&nbsp;</span>
                </p>

                <div className="product-color-content">
                    <span className="h4">{product.title}</span>
                    <span className="tip-large">{m.trust(product.subtextFirstLine)}</span>
                    <i className="icon-arrow-right"></i>
                </div>
            </a>
        </div>
    }
}

mounter<IOverview>(`[data-m-product-grid-slider]`, (r, d) => {
    m.mount(r, {
        view: v => <ProductGridSlider {...d} />
    })
})