import { getRootElements } from "oj-dom-utils"

export const isTouchDevice = () =>
    "ontouchstart" in window

export const isMobileViewport = (width = 768) =>
    window.innerWidth <= width

export const getFileExtensionFromString = (str: string) =>
    str.split(".")[1]

export const mounter = <T extends {}>(selector: string, cb: (root: HTMLElement, data: T | null) => void) =>
    getRootElements(selector)
        .forEach(x => {
            const inputs = Array.from(x.querySelectorAll("input"))
            let data: any = null
            if (inputs.length > 1)
                data = inputs.map(x => {
                    try {
                        return JSON.parse(x.value)
                    } catch (err) {
                        return x.value
                    }
                })
            else if (inputs[0] instanceof HTMLInputElement)
                try {
                    data = JSON.parse(inputs[0].value)
                } catch (err) {
                    data = inputs[0].value
                }
            cb(x, data)
        })

export const downloadFile = (response: any, name: string) => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0)
        window.navigator["msSaveBlob"](response, name)
    else {
        const blob = new Blob([response], { type: response.type })
        const objectUrl = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        document.body.appendChild(a)
        a.href = objectUrl
        a.download = name
        a.click()
        URL.revokeObjectURL(objectUrl)
    }
}

export const testMailAddress = (mail: string) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)

export const loadScript = (src: string) =>
    new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.onload = resolve
        script.onerror = reject
        script.src = src
        document.body.appendChild(script)
    })

export const loadScriptSource = (txt: string) =>
    new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.onload = resolve
        script.onerror = reject
        script.innerHTML = txt
        document.body.appendChild(script)
    })